import React from 'react'
import { Link } from 'gatsby'
import styles from './footer.module.scss'

export default () => (
  <nav role="navigation">
    <ul className={styles.navigation}>

      <li className={styles.navigationItem}>
        <a target="-_blank" rel="noopener" className={`img-box ${styles.ig} block`} href="https://instagram.com/yurika_sakaguchi">
        </a>
      </li>
      <li className={styles.navigationItem}>
        <a target="-_blank" rel="noopener" className={`img-box ${styles.tw} block`} href="https://twitter.com/lily__ka7">
        </a>
      </li>
      <li className={styles.navigationItem}>
        <a target="-_blank" rel="noopener" className={`img-box ${styles.yt} block`} href="https://youtube.com/channel/UCQdUSJ9bN1yqrSuhsMQ-xlA">
        </a>
      </li>
      <li className={styles.navigationItem}>
        <Link className={'small'} to="/privacypolicy/">Privacy Policy</Link>
      </li>

    </ul>
  </nav>
)
